<template>
  <div class="about page-border">
    <Header style="backgroundcolor: #00ffff" />
    <main>
      <div class="about-bigtxt desktopPadding mobilePadding">
        <h1>La nostra <br />storia</h1>
        <h1 style="margin-bottom: 0">
          <span>La Mediterranea</span> è la famiglia Gurrieri, che dal 1985, si
          dedica con passione alla produzione di <span>crisantemi</span>.
        </h1>
      </div>
      <div class="about-img desktopPadding">
        <img src="../assets/about-img.png" alt="" />
      </div>

      <div class="storia desktopPadding mobilePadding">
        <div class="white-rect">la nostra storia</div>
        <p class="about-desc">
          La Società Consortile Agricola La Mediterranea s.r.l. è leader in
          Europa nella coltivazione del crisantemo programmato a ciclo continuo,
          ed ha sede ad Acate, in provincia di Ragusa e raggruppa quattro
          aziende e società consorziate. La Società, che si estende su una
          superficie complessiva di oltre 100 ettari dei quali oltre la metà è
          coltivata con strutture olandesi del tipo "Venlo", rappresenta il
          principale produttore italiano di crisantemo programmato e una delle
          aziende leader a livello europeo (8% circa della produzione europea).
          Collabora in partnership con le più importanti ditte ibridatrici
          olandesi, che detengono i brevetti per gran parte delle varietà in
          coltivazione. La Mediterranea testa ogni anno centinaia di' nuove
          cultivar, al fine di selezionare quelle popolazioni che presentano le
          maggiori potenzialità di mercato ed elevata resistenza a parassiti e
          patogeni.
          <!--           <span v-show="isActive">
            <p>
              Lorem ipsum dolor, sit amet consectetur adipisicing elit.
              Consequatur rerum voluptate molestias eaque, fuga porro quo ab
              quam harum est. Lorem ipsum dolor sit amet consectetur adipisicing elit. Explicabo amet veniam voluptates ad ea tenetur, adipisci nesciunt rem, dicta quae, laudantium eos. Sunt, est mollitia!
            </p>
          </span> -->
        </p>
        <!--         <button class="read-more" @click="readMore()">
          <p>leggi di più</p>
        </button> -->
      </div>

      <div class="about-slider-wrapper desktopPadding mobilePadding">
        <div class="swiper-container container-fluid">
          <div class="row">
            <div class="col-1 hideMobile">
              <img src="../assets/swiper-black-sx.svg" class="prev-one" />
            </div>

            <div class="col-lg-10">
              <AboutSwiper />
            </div>

            <div class="col-1 hideMobile">
              <img src="../assets/swiper-black-dx.svg" class="next-one" />
            </div>
          </div>
        </div>
        <div class="slider-txt">
          <p>
            Le tecnologie installate, hanno consentito di poter ottenere un
            prodotto caratterizzato da uno standard qualitativo elevatissimo,
            pari o superiore a quello olandese, riferimento mondiale nella
            floricoltura, e di abbattere nello stesso tempo i costi energetici e
            quelli produttivi.
          </p>
        </div>
        <div class="percentuali container-fluid">
          <div class="row">
            <div class="col-12 col-lg-6">
              <h1>50%</h1>
              <p>
                Grazie agli investimenti effettuati, la produttività per metro
                quadrato di superficie coltivata è cresciuta di oltre il 50%.
              </p>
            </div>

            <div class="col-12 col-lg-6">
              <h1>40%</h1>
              <p>
                È stato possibile ridurre il costo della manodopera del 40%.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div class="about-orange container-fluid desktopPadding">
        <h1 class="mobilePadding">
          <span>Coltiviamo bellezza</span><br />dal 1985
        </h1>
        <div class="mission">
          <div class="mission-txt mobilePadding">
            <div class="white-rect">la nostra mission</div>
            <p class="about-desc">
              Oltre ad essere uno dei maggiori esportatori di crisantemo a
              livello internazionale La Mediterranea è anche una delle aziende
              floro vivaistiche siciliane più avanzate del punto di vista
              tecnologico. Tutti gli impianti di coltivazione infatti, risultano
              completamente informatizzati, consentendo di regolare tutte le
              variabili ambientali e nutrizionali della coltivazione, regolando
              accrescimento e fioritura, al punto di poterla programmare in uno
              specifico giorno dell'anno Le innovazioni poste in essere hanno
              reso possibile ottenere mediamente quattro coltivazioni per
              ciascun anno solare.
              <!--               <span v-show="isActive1">
                <p>
                  Lorem ipsum dolor, sit amet consectetur adipisicing elit.
                  Consequatur rerum voluptate molestias eaque, fuga porro quo ab
                  quam harum est. Lorem ipsum dolor sit amet consectetur adipisicing elit. Vitae, quo.
                  Lorem ipsum dolor sit amet consectetur, adipisicing elit. Ipsum sit fugiat blanditiis rerum quos aliquid, in quaerat!
                </p>
              </span> -->
            </p>
            <!--             <button class="read-more" @click="readMore1()">
              <p>Leggi di più</p>
            </button> -->
          </div>

          <img src="../assets/about-img-2.png" alt="" />
        </div>
      </div>

      <div class="about-fiori desktopPadding">
        <p>
          La svolta più significativa nella trasformazione delle strutture
          produttive risale al 1994, quando dalle coltivazioni praticate nelle
          più tradizionali serre a tunnel si è passati alle strutture innovative
          in alluminio e vetro traslucido di produzione olandese, che hanno
          consentito di abbattere drasticamente i consumi energetici, voce più
          alta del costo di produzione nel bilancio aziendale, e di
          razionalizzare ed automatizzare l'intero ciclo produttivo.
        </p>
        <img src="../assets/about-fiore1.png" alt="" />
        <img src="../assets/about-fiore2.png" alt="" />
      </div>
    </main>
    <Footer />
  </div>
</template>

<script>
import Footer from "../components/Footer.vue";
import Header from "../components/Header.vue";
import AboutSwiper from "../components/AboutSwiper.vue";

export default {
  components: {
    Header,
    Footer,
    AboutSwiper,
  },

  mounted() {
    document.body.style.overflowY = "scroll";
    window.scrollTo(0, 0);
  },

  data() {
    return {
      isActive: false,
      isActive1: false,
    };
  },

  methods: {
    readMore() {
      this.isActive = !this.isActive;
    },
    readMore1() {
      this.isActive1 = !this.isActive1;
    },
  },
};
</script>

<style>
</style>
