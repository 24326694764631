<template>
  <swiper ref="mySwiper" :options="swiperOptions">
    <!-- === SLIDE 1 === -->

    <swiper-slide>
    <img src="../assets/about-sw-1.jpeg" alt="">
    </swiper-slide>

    <!-- === SLIDE 2 === -->

    <swiper-slide>
         <img src="../assets/about-sw-2.jpeg" alt="">

    </swiper-slide>

    <!-- === SLIDE 3 === -->

    <swiper-slide>
         <img src="../assets/about-sw-3.jpeg" alt="">

    </swiper-slide>
    <div class="swiper-pagination hideDesktop" slot="pagination"></div>
  </swiper>
</template>

<script>
import { Swiper, SwiperSlide, directive } from "vue-awesome-swiper";
import "swiper/css/swiper.css";
export default {
  name: "CatalogoSwiper",

  components: {
    Swiper,
    SwiperSlide,
  },
  directives: {
    swiper: directive,
  },
  data() {
    return {
      swiperOptions: {
        navigation: {
          nextEl: ".next-one",
          prevEl: ".prev-one",
        },
         pagination: {
            el: '.swiper-pagination'
          },
      },
    };
  },
};
</script>